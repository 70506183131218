<!--  -->
<template>
  <div>
    <el-form label-position='left' label-width="100px" ref='allForm' :model='allForm'>
        <el-form-item  v-for="(item) in allText.slice(0,3)" :key='item.id' :label="item.title" :prop='item.formName'
           :rules="[{ required: true, message: '请输入'+item.title, trigger: 'blur' }]">
			    <el-input v-model="allForm[item.formName]" :placeholder="'请输入'+item.title"></el-input>
		    </el-form-item>
        <el-form-item  v-for="(item) in allText.slice(3,4)" :key='item.id' :label="item.title" :prop='item.formName'
           :rules="[{ required: true, message: '请输入'+item.title, trigger: 'change' }]">
			      <el-select v-model="allForm[item.formName]" :placeholder="'请选择'+item.title">
              <el-option  label="停用" value='0'></el-option>
              <el-option  label="启用" value='1'></el-option>
            </el-select>
        </el-form-item>
    </el-form>

        <div class='btn-list'>
            <el-button @click="sureCreate('allForm')" type="primary">确定</el-button>
            <el-button @click="cancelCreate()" type="info">取消</el-button>
        </div>
  </div>
</template>

<script>

import { eqUpdate } from '@/api/entranceguard'
export default {
  props:{
    isAdd:{
      type:Object
    }
  },
  mounted(){
      this.allForm =  JSON.parse(JSON.stringify(this.isAdd.lineData))
      this.doorcode = this.isAdd.lineData.doorcode
      console.log(this.isAdd.lineData)
  },
  data () {
    return {
        doorcode:'',//编号
        allForm:{
            street_name:'',
            valuebane:'',
            checkflag:'',
            paixu:'',
        },//所有任务类填报表单
        allText:[
            {id:3,title:'排序',formName:'paixu'},
            {id:0,title:'备注',formName:'street_name'},
            {id:1,title:'显示名称	',formName:'valuebane'},
            {id:2,title:'启用状态',formName:'checkflag'},
        ],//所有任务类填报项
    };
  },

  methods: {
      sureCreate(formName){
        this.$refs[formName].validate((valid) => {
            if (valid) {
              eqUpdate({
                userid: localStorage.getItem('userid'),	
                doorcode:this.doorcode,
                paixu:this.allForm.paixu,
                street_name:this.allForm.street_name,
                valuebane:this.allForm.valuebane,
                checkflag:this.allForm.checkflag,
              }).then(res=>{
                if (res.result == 200) {
                    this.$message.success('修改成功')
                    this.cancelCreate()
                    this.$parent.$parent.getList()
                  }else{
                    this.$message.error(res.detail.description)
                  }
              })
            } else {
              return false
            }
        })
      },//确认添加
      cancelCreate(){
          this.firstForm('allForm')
          this.$emit('formDialog')
      },//取消添加
      firstForm(formName){
        this.$refs[formName].resetFields();
      },//初始化表单
  }
}

</script>
<style lang='scss'>
    .btn-list{
        text-align: center;
    }
</style>