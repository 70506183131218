<template>
  <div style="margin: 10px">
    <el-form
      style="display: flex; flex-wrap: wrap"
      :model="searchlist"
      label-width="150px"
      ref="searchlist"
    >
      <!-- v-for循环截取循环的数组-->
      <el-form-item
        label-width="80px"
        v-for="item in searchType.slice(0, 4)"
        :key="searchType.indexOf(item).doorcode"
        :label="item.title"
      >
        <el-input
          style="width: 200px !important"
          :placeholder="'请输入' + item.title + '查询'"
          v-model="searchlist[item.formName]"
        ></el-input>
      </el-form-item>
      <el-form-item
        label-width="80px"
        v-for="item in searchType.slice(4, 5)"
        :key="searchType.indexOf(item).doorcode"
        :label="item.title"
      >
        <el-select
          style="width: 200px !important"
          clearable
          :placeholder="'请输入' + item.title + '查询'"
          v-model="searchlist[item.formName]"
        >
          <el-option label="已停用" value="0"></el-option>
          <el-option label="启用中" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label-width="10px">
        <el-button type="primary" @click="search">
          <i class="el-icon-search" style="font-size: 14px; color: white"></i
          >搜索
        </el-button>
      </el-form-item>
    </el-form>

    <div>
      <el-table
        :data="tableData"
        border
        style="width: 100%; margin-top: 20px"
        stripe
      >
        <el-table-column
          v-for="item in formtype.slice(0, 5)"
          :prop="item.formvalue"
          show-overflow-tooltip
          :label="item.name"
          align="center"
          :key="formtype.indexOf(item).doorcode"
        >
        </el-table-column>
        <el-table-column
          v-for="item in formtype.slice(4, 5)"
          :prop="item.formvalue"
          show-overflow-tooltip
          :label="item.name"
          align="center"
          :formatter="userType"
          :key="formtype.indexOf(item).doorcode"
        >
        </el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <div
            slot-scope="scope"
            style="display: flex; flex-wrap: wrap; justify-content: center"
          >
            <el-button
              type="primary"
              @click="change({ add: false, lineData: scope.row })"
              >修改</el-button
            >
          </div> </el-table-column
        ><!-- 不同状态对应操作-->
      </el-table>
    </div>

    <div class="dialog">
      <el-dialog
        title="设备修改"
        :close-on-click-modal="false"
        :visible.sync="formDialog"
        v-if="formDialog"
        append-to-body
        width="30%"
      >
        <!-- <span style="font-size: 20px;color:black" slot="title">新建账户</span> -->
        <AddForm @formDialog="closeDialog" :isAdd="isAdd"></AddForm>
      </el-dialog>
    </div>

    <div class="fenyekuang">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { eqQueryPage } from '@/api/entranceguard'
import AddForm from "./form.vue";
export default {
  components: {
    AddForm,
  },
  mounted() {
    this.getList();
  },
  computed: {},
  data() {
    return {
      tableData: [], //获取的表格数据
      formDialog: false, //弹出框
      formtype: [
				{ name: "顺序号", formvalue: "paixu" },
        { name: "显示名称", formvalue: "valuebane" },
        { name: "备注", formvalue: "street_name" },
        { name: "编号", formvalue: "doorcode" },
        { name: "编码", formvalue: "boxcode" },
        { name: "启用状态", formvalue: "checkflag" },
      ], //表单须填数据模板
      searchlist: {
        boxcode: "",
        doorcode: "",
        street_name: "",
        valuebane: "",
        checkflag: "1",
      }, //搜索数据
      searchType: [
        { title: "编码", formName: "boxcode" },
        { title: "编号", formName: "doorcode" },
        { title: "备注", formName: "street_name" },
        { title: "显示名称", formName: "valuebane" },
        { title: "启用状态", formName: "checkflag" },
      ], //搜索框成员
      currentPage: 1, //当前页
      fenye: {
        pagesizes: [10, 20, 50],
        pagesize: 10,
        totalnum: 0,
      }, //分页数据
      isAdd: null, //判断是否是打开新增框
    };
  },
  methods: {
    userType(row) {
      if (row.checkflag == 0) {
        return "已停用";
      } else {
        return "启用中";
      }
    },
    getList() {
			eqQueryPage({
				userid: localStorage.getItem("userid"),
				boxcode: this.searchlist.boxcode,
				doorcode: this.searchlist.doorcode,
				street_name: this.searchlist.street_name,
				valuebane: this.searchlist.valuebane,
				checkflag: this.searchlist.checkflag,
				page: this.currentPage,
				pagesize: this.fenye.pagesize
			}).then(res=>{
				if (res.result == 200) {
          this.tableData = res.detail.list;
          this.fenye.totalnum = res.detail.totalRow;
          // console.log(res);
        } else {
          this.$message.error(res.detail.description);
        }
			})
    }, //获取表格数据和查询请求
    search() {
      this.currentPage = 1;
      this.getList();
    }, //搜索请求
    sizeChange(val) {
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    }, //展示条数改变时
    currentChange(val) {
      this.currentPage = val;
      this.getList();
    }, //当前页改变时
    closeDialog() {
      this.formDialog = false;
    }, //关闭表格
    change(row) {
      this.isAdd = row;
      this.formDialog = true;
    }, //详情框
  },
};
</script>
<style lang='scss'>
.fenyekuang {
  text-align: right;
  margin-top: 20px;
}
</style>


